<template>
  <div class="warp">
    <van-form @submit="onSubmit">
      <van-field
        label-width="100"
        v-model="query.comName"
        name="企业名称"
        label="企业名称"
        placeholder="请输入企业名称"
        :rules="[{ required: true, message: '请填写企业名称' }]"
      />
      <!-- 111111111 -->
      <van-field
        readonly
        clickable
        label-width="100"
        :value="divisionAreaIds"
        label="所属行政区"
        placeholder="点击选择所属行政区"
        @click="showPicker0 = true"
        :rules="[{ required: true, message: '请选择所属行政区' }]"
      />

      <van-popup v-model="showPicker0" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择省市区"
          :options="columns0"
          :field-names="fieldNames"
          @close="showPicker0 = false"
          @finish="onFinish"
        />
      </van-popup>

      <van-field
        readonly
        label-width="100"
        clickable
        name="选择街道"
        :value="divisionAreaId"
        label="选择街道"
        placeholder="点击选择街道"
        @click="showPicker5 = true"
      />

      <van-popup v-model="showPicker5" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns5"
          @confirm="onConfirm5"
          @cancel="showPicker5 = false"
        />
      </van-popup>
      <!-- 111111111 -->
      <van-field
        label-width="100"
        v-model="query.uscc"
        name="统一征信代码"
        label="统一征信代码"
        placeholder="请输入数字和大写字母"
        :rules="[{ required: true, message: '请填写统一征信代码' }]"
      />
      <van-field
        label-width="100"
        v-model="query.managerName"
        name="环保负责人姓名"
        label="环保负责人姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '请填写环保负责人姓名' }]"
      />
      <van-field
        label-width="100"
        v-model="query.managerPhone"
        name="环保负责人电话"
        label="环保负责人电话"
        placeholder="请输入环保负责人电话"
        :rules="[{ required: true, message: '请填写环保负责人电话' }]"
      />
      <van-field
        label-width="100"
        v-model="query.legalName"
        name="企业负责人姓名"
        label="企业负责人姓名"
        placeholder="请输入企业负责人姓名"
        :rules="[{ required: true, message: '请填写企业负责人姓名' }]"
      />
      <van-field
        label-width="100"
        v-model="query.legalPhone"
        name="企业负责人电话"
        label="企业负责人电话"
        placeholder="请输入企业负责人电话"
        :rules="[{ required: true, message: '请填写企业负责人电话' }]"
      />
      <van-field
        readonly
        label-width="100"
        clickable
        name="所属环保局"
        :value="inEpa"
        label="所属环保局"
        placeholder="点击选择所属环保局"
        @click="showPicker4 = true"
        :rules="[{ required: true, message: '请填写所属环保局' }]"
      />
      <van-popup v-model="showPicker4" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns4"
          @confirm="onConfirm4"
          @cancel="showPicker4 = false"
        />
      </van-popup>
      <div class="btn">
        <van-button v-if="isShowbtn" round block type="info" native-type="submit">提交</van-button>
        <van-button v-else loading  type="primary" disabled block loading-text="请稍等..." />
      </div>
    </van-form>
  </div>
</template>

<script>
import { register, getInfo, jsapisignature, dictType } from '@/api/notice.js'
import { Toast } from 'vant'
import { getDivisionList } from '@/api/order.js'
import pcaCode from '@/assets/data/pca-code.json'

export default {
  data () {
    return {
      inEpa: undefined, // 选择
      isShowbtn: true,
      columns4: [],
      showPicker4: false,
      query: {
        comName: undefined, // 企业名称
        uscc: undefined, // 统一征信代码
        managerName: undefined, // 环保负责人姓名
        managerPhone: undefined, // 手机号码
        legalName: undefined, // 法人姓名
        legalPhone: undefined, // 法人电话
        inEpa: undefined, // 所属环保局
        params: {
          divisionAreaId: undefined
        }, // 行政区域代码
      },
      fieldNames: {
        text: 'text',
        value: 'id',
        children: 'children',
      },
      querys: {
        divisionId: undefined,
        level: 4,
      },
      cascaderValue: undefined,
      divisionAreaId: undefined,
      divisionAreaIds: undefined,
      columns0: pcaCode,
      columns5: [],
      showPicker0: false,
      showPicker5: false,
    }
  },
  created() {
    this.dictType()
  },
  methods: {
    getInfo() {
      getInfo().then(res => {
        if (res.data.code === 200 && res.data.data.hsCompanyUser) {
          const isPass = res.data.data.hsCompanyUser.isPass
          if (isPass === 1) {
            this.$router.push({path: 'Errorpage', query: {msg: '对不起，您的微信号已经绑定过企业了，无需重复绑定哦'}})
          } else if (isPass === 2) {
            Toast('您的审核未通过，请重新申请')
          } else if (isPass === 0) {
            this.$router.push({path: 'Errorpage', query: {msg: '您的申请正在审核中'}})
          }
        }
      })
    },
    dictType() { // 字典
      dictType('hs_company_inEpa').then(res => { // 所属环保局
      if (res.data.code === 200) {
        this.columns4 = (res.data.data || []).map(item => {
          return {
            id: item.dictLabel,
            text: item.dictLabel
          }
        })
        this.getInfo()
      }
    })
    },
    jsapisignature() {
      let hrefurl = sessionStorage.getItem("url")
      jsapiSignature({url: hrefurl}).then(res => {
        setTimeout(function () {
          wx.config({
            debug: false,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ['closeWindow']
          })
          wx.ready(function () {
            wx.closeWindow()
          })
        }, 2000)
      })
    },
    onSubmit(values) {
      this.isShowbtn = false
      register(this.query).then(res => {
        if (res.data.code === 200) {
          Toast('信息提交成功请等待审核')
          this.jsapisignature() // 关闭窗口
        }
      })
    },
    onConfirm4(value) {
      this.showPicker4 = false;
      this.query.inEpa = value.id
      this.inEpa = value.text
    },
    getDivisionList() { // 获取街道
      getDivisionList(this.querys).then(res => {
        if (res.data.code === 200) {
          this.columns5 = (res.data.data.divisions || []).map(item => {
            return {
              id: item.divisionId,
              text: item.name
            }
          })
        }
      })
    },
    onFinish({ selectedOptions }) {
      this.showPicker0 = false
      this.divisionAreaId = ''
      this.divisionAreaIds = selectedOptions.map((option) => option.text).join('/');
      const codes = selectedOptions.map((option) => option.id).slice(-1)[0]
      if (codes) {
        this.querys.divisionId = codes
        this.getDivisionList()
      }
    },
    onConfirm5(value) {
      this.showPicker5 = false;
      this.query.params.divisionAreaId = value.id
      this.divisionAreaId = value.text
    },
  }
}

</script>
<style lang="scss" scoped>
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
